import '../../../css/views/homepage.pcss';
import '../calendar/webComponents/CalendarInput';
import '../calendar/webComponents/DatePickerCalendar';
import '../../ui/web-components/searchBar/SearchBar';
import '../../ui/web-components/searchBar/DestinationSearch';
import GoogleAnalyticsEvents from '../../common/GoogleAnalyticsEvents';
import NavigationHelper from './NavigationHelper';

export default class HomepageModule {
    public initialize(): void {
        this.#trackQ3Banner();
        this.#trackLastViewedHouses();
        this.#trackTeaserCards();

        NavigationHelper.getInstance();
    }

    #trackLastViewedHouses(): void {
        const lastViewedHouses: HTMLAnchorElement[] = Array.from(
            document.querySelectorAll(
                '#nh-houses-carousel [data-carousel-item] .nh-card--small__anchor'
            )
        );

        for (const card of lastViewedHouses) {
            const title = card.title ?? '';
            const id = `homepage_last_viewed_${card.dataset.houseId}`;

            const data: GoogleAnalytics4PromotionEvent = {
                items: [
                    {
                        item_id: id,
                        currency: 'EUR'
                    }
                ],
                creative_name: title,
                creative_slot: id,
                promotion_id: 'homepage_last_viewed',
                promotion_name: 'Homepage Last Viewed'
            };

            this.#trackViewPromotion(card, data);

            card.addEventListener('click', (event: Event) => {
                this.#trackSelectPromotion(event, data);
            });
        }
    }

    #trackTeaserCards(): void {
        const teaserCards: HTMLAnchorElement[] = Array.from(
            document.querySelectorAll('section.location-links [data-carousel-item] > a')
        );

        for (const [index, card] of teaserCards.entries()) {
            const title = card.title ?? '';
            const id = `homepage_teaser_cards_${index}`;

            const data: GoogleAnalytics4PromotionEvent = {
                items: [
                    {
                        item_id: id,
                        currency: 'EUR'
                    }
                ],
                creative_name: title,
                creative_slot: id,
                promotion_id: 'homepage_teaser_cards',
                promotion_name: 'Homepage Teaser Cards'
            };

            this.#trackViewPromotion(card, data);

            card.addEventListener('click', (event: Event) => {
                this.#trackSelectPromotion(event, data);
            });
        }
    }

    #trackQ3Banner(): void {
        const section: HTMLElement | null = document.querySelector('.q3-section');

        if (section === null) {
            return;
        }

        const promotionItem: GoogleAnalytics4Item = {
            item_id: 'homepage_q3_teaser_block',
            currency: 'EUR'
        };

        const ga4Data: GoogleAnalytics4PromotionEvent = {
            items: [promotionItem],
            creative_name: 'Homepage Teaser Block',
            creative_slot: 'homepage_teaser_block',
            promotion_id: 'q3_campaign',
            promotion_name: 'Q3 campagne - Niet vliegen, wel landen'
        };

        this.#trackViewPromotion(section, ga4Data);

        const button = section.querySelector('.nh-teaser-block__button');
        if (button === null) {
            return;
        }

        button.addEventListener('click', (event: Event) => {
            this.#trackSelectPromotion(event, ga4Data);
        });
    }

    #trackViewPromotion(section: HTMLElement, data: GoogleAnalytics4PromotionEvent): void {
        if ('IntersectionObserver' in window) {
            const observer = new IntersectionObserver(
                (e) => {
                    e.forEach((entry) => {
                        if (entry.isIntersecting) {
                            GoogleAnalyticsEvents.viewPromotion(data);
                            observer.unobserve(section);
                        }
                    });
                },
                {
                    root: null,
                    threshold: 1.0
                }
            );
            observer.observe(section);
        }
    }

    #trackSelectPromotion(event: Event, data: GoogleAnalytics4PromotionEvent): void {
        event.preventDefault();
        const target = event.currentTarget as HTMLAnchorElement;
        GoogleAnalyticsEvents.selectPromotion(data);

        if (target instanceof HTMLAnchorElement) {
            window.location.href = target.href;
        }
    }
}

(function homepageModuleInit(): void {
    const homepageModule: HomepageModule = new HomepageModule();
    homepageModule.initialize();
})();
