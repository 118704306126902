export function getCategoryName(): string {
    const breadcrumbs: HTMLElement[] = Array.from(document.querySelectorAll('.breadcrumb li'));
    const titleParts: string[] = [];

    for (let index = 0; index < breadcrumbs.length; index++) {
        if (index === 0 || (breadcrumbs.length > 2 && index === 1)) {
            continue;
        }

        const breadcrumb: HTMLElement = breadcrumbs[index];
        if (!breadcrumb.textContent) {
            continue;
        }

        titleParts.push(breadcrumb.textContent.replace(/^\s+|\s+$/g, ''));
    }

    return titleParts.join(' > ');
}

export function getPosition(index: number): number {
    const position: number = index;
    const url: URL = new URL(window.location.href);
    const skipAmount: number = Number(url.searchParams.get('skip')) || 0;

    return position + skipAmount;
}

export function getNumberOfNights(arrivalDate: Date, departureDate: Date): number {
    const timeDiff: number = Math.abs(departureDate.getTime() - arrivalDate.getTime());
    return Math.ceil(timeDiff / (1000 * 3600 * 24));
}

export function convertPriceStringToNumber(value: string | number | null): number | null {
    if (typeof value === 'string') {
        return Number(value.replace(',', '.'));
    }

    return value;
}

export function getCompanionData(container: HTMLElement): TravelPartyDataInterface {
    return {
        adults: Number(
            (<HTMLInputElement>container.querySelector('[data-field-name="adult"]')).value
        ),
        children: Number(
            (<HTMLInputElement>container.querySelector('[data-field-name="child"]')).value
        ),
        babies: Number(
            (<HTMLInputElement>container.querySelector('[data-field-name="baby"]')).value
        ),
        pets: Number((<HTMLInputElement>container.querySelector('[data-field-name="pet"]')).value)
    };
}
